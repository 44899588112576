import {Outlet, Link} from 'react-router-dom';

export default function MainLayout() {
    return (
        <>
            <div style={{padding: 15, backgroundColor: '#cecece'}}>
                Navigation<br/>
                
                <Link to={'/customers'}>Customers</Link>&nbsp; 
                <Link to={'/suppliers'}>Suppliers</Link>&nbsp; 
                <Link to={'/settings'}>Settings</Link>&nbsp; 
            </div>
            <div>
                <Outlet />   
            </div>
            <div style={{padding: 15, backgroundColor: '#cecece'}}>
                Footer
            </div>
        </>
    );
}