import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainLayout from './MainLayout';
import HomeApp from './pages/HomeApp';
import EmailSetts from './pages/EmailSettings';
import CustomerSetts from './pages/CustomerApp';
import SuppliersSetts from './pages/SuppliersApp';
import SettingsSetts from './pages/SettingsApp';
import UserProfile from './pages/UserProfile';

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout/>}>          
          <Route index element={<HomeApp />} /> 
          <Route path="customers" element={<CustomerSetts />} />        
          <Route path="suppliers" element={<SuppliersSetts />} />    
          <Route path="/settings" element={<SettingsSetts/>}>   
            <Route path="emails" element={<EmailSetts />} />        
            <Route path="userprofile" element={<UserProfile />} />    
          </Route>
        </Route>
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
