import {Outlet, Link} from 'react-router-dom';


const SettingsApp = () => {
    return (
        <>
            <div style={{padding: 13}}>
                <h3>SettingsApp</h3>
            </div>
            <div>
                <Link to={'/settings/emails'}>Emails</Link><br/> 
                <Link to={'/settings/userprofile'}>User profile</Link><br/>  
                <br />  
                <Outlet />    
            </div>
        </>
    );
}

export default SettingsApp;